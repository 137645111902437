import React, { Fragment, useEffect, useRef, useState } from "react";
import EditableTextField from "./EditableTextField";
import Excel from "exceljs";
import ProgressBar from "./ProgressBar";
import Cookies from "js-cookie";

const ConclusionDropdown = ({ data, index, dataKey, updateMessageContent }) => {
  // Trouver l'exigence correspondante
  const requirement = data.exigences.find((item) => item.exigence === dataKey);

  // Si l'exigence n'existe pas, afficher un message d'erreur ou gérer le cas
  if (!requirement) {
    console.error("Exigence non trouvée pour la clé:", dataKey);
    return null;
  }

  const handleChange = (event) => {
    const newConclusion = event.target.value;
    // Mettre à jour la conclusion
    requirement.conclusion.conforme = parseInt(newConclusion);
    updateMessageContent(index, data);
  };

  return (
    <select
      onChange={handleChange}
      value={requirement.conclusion.conforme || "-"}
    >
      <option value="0">✅</option>
      <option value="-1">❌</option>
      <option value="1">🟡</option>
    </select>
  );
};

const NormxRender = ({
  Request,
  editable,
  data,
  index,
  updateMessageContent,
  selectedChat,
  updateChatDb,
  sharedChat,
  shared,
  setShowPdfViewer,
  setPdfFile,
  setPdfPage,
  setHighlightText,
}) => {
  const [openCategories, setOpenCategories] = useState({});
  const [openFiles, setOpenFiles] = useState({});
  const [openRows, setOpenRows] = useState({});
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedNormes, setSelectedNormes] = useState([]);
  const [selectedExtraits, setSelectedExtraits] = useState([]);

  const [exigences, setExigences] = useState([]);

  const [status, setStatus] = useState(0);
  const [progress, setProgress] = useState(0);
  const [exigencesLenght, setExigencesLenght] = useState(1);

  const [expandedRow, setExpandedRow] = useState(null);

  const apiUrl = Cookies.get("apiUrl");

  const dataRef = useRef();

  useEffect(() => {
    setExigences(data.exigences);
    setStatus(data.status);
  }, [data]);

  useEffect(() => {
    if (status < 100) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [status]);

  const toggleRow = (key) => {
    setOpenRows((prevState) => {
      const newState = { ...prevState };
      if (newState.hasOwnProperty(key)) {
        delete newState[key];
      } else {
        newState[key] = true;
      }
      return newState;
    });
  };

  const toggleFile = (key, fileName) => {
    setOpenFiles({
      ...openFiles,
      [`${key}-${fileName}`]: !openFiles[`${key}-${fileName}`],
    });
  };

  const transformFinalData = (finalData) => {
    return Object.keys(finalData).map((key) => {
      let item = finalData[key];
      return {
        Exigence: key,
        Description: item.label,
        Avis:
          item.conclusion === "0"
            ? "Conforme"
            : item.conclusion === "-1"
            ? "Non Conforme"
            : "A vérifier",
        Commentaire: item.explication || "",
      };
    });
  };

  const transformFileData = (exigenceData, apiUrl) => {
    let fileDetails = [];
    exigenceData.files.forEach((file) => {
      file.result.forEach((result) => {
        const fileName = file.name.split("/").pop();
        const filePath = `${file.link}`;
        fileDetails.push({
          NomFichier: {
            text: fileName,
            hyperlink: filePath,
            font: { color: { argb: "FF0000FF" }, underline: true },
          },
          Extrait: result.information.extrait,
          Explication: result.information.explication,
          Conforme:
            parseInt(result.conforme) === 0
              ? "Conforme"
              : parseInt(result.conforme) === -1
              ? "Non conforme"
              : "A vérifier",
          Page: {
            text: `${result.page}`,
            hyperlink: `${file.link}#page=${result.page}`,
            font: { color: { argb: "FF0000FF" }, underline: true },
          },
          Poids: result.poids,
        });
      });
    });
    return fileDetails;
  };

  const downloadExcel = async () => {
    const workbook = new Excel.Workbook();

    const finalData = data["afterhuman"];
    const transformedFinalData = transformFinalData(finalData);

    const wsFinal = workbook.addWorksheet("Résultats Finaux");

    wsFinal.columns = [
      { header: "Exigence", key: "Exigence", width: 20 },
      { header: "Description", key: "Description", width: 30 },
      { header: "Avis", key: "Avis", width: 10 },
      { header: "Commentaire", key: "Commentaire", width: 30 },
    ];

    transformedFinalData.forEach((item, index) => {
      const row = wsFinal.addRow(item);
      const cell = row.getCell("Exigence");
      cell.value = {
        text: item.Exigence,
        hyperlink: `'#${item.Exigence}!A1'`,
      };
      cell.font = { color: { argb: "FF0000FF" }, underline: true };
    });

    Object.keys(finalData).forEach((key) => {
      const exigenceData = finalData[key];
      const transformedFileData = transformFileData(exigenceData, apiUrl);
      const ws = workbook.addWorksheet(key);

      ws.columns = [
        { header: "NomFichier", key: "NomFichier", width: 30 },
        { header: "Extrait", key: "Extrait", width: 50 },
        { header: "Explication", key: "Explication", width: 50 },
        { header: "Conforme", key: "Conforme", width: 10 },
        { header: "Page", key: "Page", width: 10 },
        { header: "Poids", key: "Poids", width: 10 },
      ];

      transformedFileData.forEach((data) => {
        const row = ws.addRow({
          NomFichier: data.NomFichier.text,
          Extrait: data.Extrait,
          Explication: data.Explication,
          Conforme: data.Conforme,
          Page: data.Page.text,
          Fiabilité: data.Fiabilité,
          Poids: data.Poids,
        });

        const fileNameCell = row.getCell("NomFichier");
        fileNameCell.value = {
          text: data.NomFichier.text,
          hyperlink: data.NomFichier.hyperlink,
        };
        fileNameCell.font = data.NomFichier.font;

        const pageCell = row.getCell("Page");
        pageCell.value = {
          text: data.Page.text,
          hyperlink: data.Page.hyperlink,
        };
        pageCell.font = data.Page.font;
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    let dlname = `${data.utils.name}-v${data.utils.version}-${data.utils.date}`;
    link.download = `${dlname}.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data))
      .then(() => {
        alert("Contenu copié avec succès !");
      })
      .catch((err) => {
        alert(`Impossible de copier le texte : ${err}`);
      });
  };

  const handleCloseAllSections = () => {
    for (const key in openFiles) {
      const fileName = key.split("-")[1];
      if (openFiles[key]) {
        toggleFile(key, fileName);
      }
    }

    for (const key in openRows) {
      if (openRows[key]) {
        toggleRow(key);
      }
    }
  };

  const handleNormeChange = (event, idx) => {
    event.stopPropagation();
    setSelectedNormes((prev) => {
      const newSelectedNormes = [...prev];
      const isNormeSelected = newSelectedNormes.includes(idx);

      if (isNormeSelected) {
        newSelectedNormes.splice(newSelectedNormes.indexOf(idx), 1);
      } else {
        newSelectedNormes.push(idx);
      }

      const normeKey = filteredKeys[idx];
      const newSelectedExtraits = [...selectedExtraits];
      dataRef.current[normeKey].files.forEach((file, fileIndex) => {
        file.result.forEach((_, resultIndex) => {
          const extraitKey = `${normeKey}-${fileIndex}-${resultIndex}`;
          if (isNormeSelected) {
            const extraitIndex = newSelectedExtraits.indexOf(extraitKey);
            if (extraitIndex > -1) {
              newSelectedExtraits.splice(extraitIndex, 1);
            }
          } else if (!newSelectedExtraits.includes(extraitKey)) {
            newSelectedExtraits.push(extraitKey);
          }
        });
      });

      setSelectedExtraits(newSelectedExtraits);
      return newSelectedNormes;
    });
  };

  const handleExtraitChange = (event, normeIdx, fileIndex, resultIndex) => {
    event.stopPropagation();
    const extraitKey = `${filteredKeys[normeIdx]}-${fileIndex}-${resultIndex}`;
    setSelectedExtraits((prev) => {
      const newSelectedExtraits = [...prev];
      if (newSelectedExtraits.includes(extraitKey)) {
        newSelectedExtraits.splice(newSelectedExtraits.indexOf(extraitKey), 1);
      } else {
        newSelectedExtraits.push(extraitKey);
      }
      return newSelectedExtraits;
    });
  };

  const handleDeleteSelected = () => {
    setLoading(true);

    const newRefData = { ...dataRef.current };

    selectedNormes.forEach((idx) => {
      const normeKey = filteredKeys[idx];
      delete newRefData[normeKey];
    });

    const newSelectedExtraits = selectedExtraits.filter((extraitKey) => {
      return !selectedNormes.some((idx) => {
        const normeKey = filteredKeys[idx];
        return extraitKey.startsWith(normeKey);
      });
    });

    newSelectedExtraits.forEach((extraitKey) => {
      const [normeKey, fileIndex, resultIndex] = extraitKey.split("-");
      if (
        dataRef.current[normeKey] &&
        dataRef.current[normeKey].files[fileIndex]
      ) {
        dataRef.current[normeKey].files[fileIndex].result.splice(
          resultIndex,
          1
        );
      }
    });

    dataRef.current = newRefData;

    setSelectedNormes([]);
    setSelectedExtraits([]);

    data["afterhuman"] = dataRef.current;

    setFilteredKeys(
      Object.keys(data["afterhuman"]).filter(
        (key) =>
          !["type", "name", "children", "metadata", "utils"].includes(key)
      )
    );

    updateMessageContent(index, data);
  };

  const handleFileClick = async (relativePath, page, extrait) => {
    try {
      const response = await Request.Get(relativePath);
      const fileUrl = response;
      setPdfFile(fileUrl);
      setPdfPage(page);
      setHighlightText(extrait);
      setShowPdfViewer(true);
    } catch (error) {
      console.error(error);
    }
  };

  const updateMetadatas = (key, value) => {
    data.utils[key] = value;
    updateMessageContent(index, data);
    updateChatDb({
      id: selectedChat.id,
      name: `DCE : ${data.utils.name}-v${data.utils.version}-${data.utils.date}`,
    });
  };

  const toggleCategory = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  // useEffect(() => {
  //   let timeBetweenSteps = Math.ceil(exigencesLenght) * 30000;

  //   const simulateProgress = () => {
  //     if (progress >= 100) {
  //       return;
  //     }

  //     if (progress < status) {
  //       setProgress(progress + 1);
  //     }
  //   };

  //   setTimeout(simulateProgress, timeBetweenSteps);

  //   simulateProgress();
  // }, [progress, status]);

  const handleButtonClick = (key, e) => {
    e.stopPropagation();
    const newDataRef = { ...dataRef.current };
    newDataRef[key] = {
      ...newDataRef[key],
      checked: !newDataRef[key].checked,
    };
    dataRef.current = newDataRef;

    updateMessageContent(index, data);
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="header">
            {!shared && <button onClick={() => sharedChat()}>Partager</button>}
          </div>

          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  {!shared && <th></th>}
                  <th>Nom d'exigence</th>
                  <th>Description d'exigence</th>
                  <th>Avis</th>
                  <th>Commentaire</th>
                </tr>
              </thead>
              <tbody>
                {exigences.map((exigence, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() => toggleCategory(index)}
                      className={expandedRow === index ? "open" : ""}
                    >
                      {!shared && (
                        <td>
                          <input
                            className="CheckBox"
                            type="checkbox"
                            checked={selectedNormes.includes(index)}
                            onChange={(e) => handleNormeChange(e, index)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </td>
                      )}
                      <td>{exigence.exigence}</td>
                      <td>{exigence.description || ""}</td>
                      <td>
                        {editable ? (
                          <ConclusionDropdown
                            data={data}
                            index={index}
                            dataKey={exigence.exigence}
                            updateMessageContent={updateMessageContent}
                          />
                        ) : exigence.conclusion.conforme === 0 ? (
                          "✅"
                        ) : exigence.conclusion.conforme === -1 ? (
                          "❌"
                        ) : (
                          "🟡"
                        )}
                      </td>
                      <td className="editable">
                        {editable && !shared ? (
                          <EditableTextField
                            data={exigence.result}
                            dataKey={exigence.exigence}
                            value={exigence.conclusion.explication || "roro"}
                            field={"explication"}
                            index={index}
                            updateMessageContent={updateMessageContent}
                          />
                        ) : (
                          exigence.conclusion.explication
                        )}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan="5">
                          <table className={exigence.comparaison ? 'compare' : 'nocompare'}>
                            <thead>
                              <tr>
                                {exigence.comparaison ? (
                                  <>
                                    <th className="conforme"></th>
                                    <th className="extrait">Extrait</th>
                                    <th className="extrait">Extrait Document</th>
                                    <th className="explication">Explication</th>
                                    <th className="page">Page Extrait</th>
                                    <th className="page">Page Document</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="conforme"></th>
                                    <th className="extrait">Extrait</th>
                                    <th className="explication">Explication</th>
                                    <th className="page">Page</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {exigence.comparaison ? (
                                exigence.result.map((result, resultIndex) => (
                                  result.comparaison.map((comparaison, compIndex) => (
                                    <tr className="comparaison" key={`${resultIndex}-${compIndex}`}>
                                      <td className="conforme">{comparaison.conforme === -1 ? '❌' : '✅'}</td>
                                      <td className="extrait">{result.extrait}<span>{result.metadatas}</span></td>
                                      <td className="extrait">{comparaison.extrait}<span>{comparaison.metadatas}</span></td>
                                      <td className="explication">{comparaison.explication}</td>
                                      <td className="page">
                                        <span
                                          onClick={() =>
                                            handleFileClick(
                                              result.fileUrl,
                                              result.page,
                                              result.extrait
                                            )
                                          }
                                          className="link"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {result.page}
                                        </span>
                                      </td>
                                      <td className="page">
                                        <span
                                          onClick={() =>
                                            handleFileClick(
                                              comparaison.fileUrl,
                                              comparaison.page,
                                              comparaison.extrait
                                            )
                                          }
                                          className="link"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {comparaison.page}
                                        </span>
                                      </td>
                                    </tr>
                                  ))
                                ))
                              ) : (
                                exigence.result.map((result, resultIndex) => (
                                  <tr className="unique" key={resultIndex}>
                                    <td className="conforme">{result.conforme === -1 ? '❌' : '✅'}</td>
                                    <td className="extrait">{result.extrait}</td>
                                    <td className="explication">{result.explication}</td>
                                    <td className="page">
                                      <span
                                        onClick={() =>
                                          handleFileClick(
                                            exigence.name,
                                            result.page,
                                            result.extrait
                                          )
                                        }
                                        className="link"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {result.page}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          { !shared && (
            <div className="footer-btn">
              <button onClick={handleCopy}>Copier le contenu</button>
            </div>
          )}
        </>
      ) : (
        <ProgressBar
          rounds={Math.ceil(exigences.length / 10)}
          initialProgress={status}
          status={status}
        />
      )}
    </>
  );
};

export default NormxRender;
