import React, { useEffect, useState } from "react";

const ChatSidebar = ({ Request, handleSelectChat, updateChatDb, ...props }) => {
  const {
    chats,
    setChats,
    selectedChat,
    setSelectedChat,
    setMessages,
    setTemplate,
    setFiles,
    plugin,
    agents,
    setSelectedAgent,
    user,
    setUser,
    defaultMessages,
    defaultTemplate,
    initTemplate
  } = props;

  // Nouveaux états pour gérer les entreprises et les utilisateurs
  const [companies, setCompanies] = useState([{
    id: "dsfsdfsd",
    name: "Normx"
  },
  {
    id: "sdjkafhkj",
    name: "Acceo"
  }]);
  
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // Récupérer la liste des entreprises au chargement
  useEffect(() => {

    if (user && user.role === "normx-admin") {
      const fetchCompanies = async () => {
        const response = await Request.Get("/protected/company/allcompanies");
        setCompanies(response);
      };
  
      fetchCompanies(); 
    }
  }, [Request, user]);

  // Récupérer les utilisateurs lorsque l'entreprise est sélectionnée
  useEffect(() => {
    if (selectedCompany) {
      const fetchUsers = async () => {
        const response = await Request.Post(`/protected/user/getallusersbycompany`, {companyId: selectedCompany});
        setUsers(response);
      };

      fetchUsers();
    }
  }, [selectedCompany, Request]);

  const deleteChat = async (chat) => {
    await Request.Delete(`/protected/chats/delete/${chat.id}`);
  };

  useEffect(() => {
    const fetchAllChats = async () => {
      const response = await Request.Get(`/protected/chats/${plugin}`);
      setChats(response.chats);
    };

    fetchAllChats();
    // eslint-disable-next-line
  }, []);

  const handleNewChat = () => {
    setSelectedChat({ name: null, id: null, favorite: false });
    setMessages(defaultMessages);
    initTemplate();
    setFiles([]);
  };

  const handleDeleteChats = async () => {
    const updatedChats = chats.filter((c) => c["favorite"] === false);

    for (const chat of updatedChats) {
      await deleteChat(chat);
    }

    setChats(chats.filter((c) => c["favorite"] === true));
  };

  const handleStarClick = (chat) => {
    const updatedChats = chats.map((c) => {
      if (c["id"] === chat.id) {
        return {
          ...c,
          favorite: !c["favorite"],
        };
      }
      return c;
    });

    setChats(updatedChats);

    const chatToUpdate = updatedChats.find((c) => c.id === chat.id);
    chatToUpdate.favorite = !chat.favorite;
    updateChatDb(chatToUpdate);
  };

  const handleTrashClick = (chat) => {
    const updatedChats = chats.filter((c) => c["id"] !== chat.id);

    setChats(updatedChats);

    deleteChat(chat);
  };

  const handleLoginAsUser = async () => {
    if (selectedUser) {
      const response = await Request.Post(`/protected/user/loginwithotheruser`, { userDestId: selectedUser._id });
      const newAuthToken = response;
      const oldWebsiteToken = getCookie("websiteToken");
      if (oldWebsiteToken) {
        document.cookie = `adminToken=${oldWebsiteToken}; path=/; secure; SameSite=Lax; max-age=3600`;
      }
      document.cookie = `websiteToken=${newAuthToken}; path=/; secure; SameSite=Lax; max-age=3600`;
      window.location.reload();
    }
  };
  
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }

  const restoreAdminSession = () => {
    const adminToken = getCookie("adminToken");
    if (adminToken) {
      // Restaurer adminToken en tant que websiteToken
      document.cookie = `websiteToken=${adminToken}; path=/; secure; SameSite=Lax; max-age=3600`;
  
      // Supprimer l'adminToken
      document.cookie = `adminToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; SameSite=Lax`;
  
      // Rafraîchir la page
      window.location.reload();
    }
  };
  
  // Vérifier si adminToken existe et afficher un bouton
  const adminToken = getCookie("adminToken");
  
  return (
    <>
      <div className="userselector">
        {adminToken && (
          <button onClick={restoreAdminSession}>
            Revenir à la session
          </button>
        )}
        
        {user && user.role === "normx-admin" && (
          <>
            <select
              value={selectedCompany?._id || ""}
              onChange={(e) => {
                const company = companies.find(c => c._id === e.target.value);
                setSelectedCompany(company);
              }}
            >
              <option value="">Sélectionner une entreprise</option>
              {companies.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.label}
                </option>
              ))}
            </select>

            {selectedCompany && users && users.length > 0 && (
              <select
                value={selectedUser?._id || ""}
                onChange={(e) => {
                  const user = users.find(u => u._id === e.target.value);
                  setSelectedUser(user);
                }}
              >
                <option value="">Sélectionner un utilisateur</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.email}
                  </option>
                ))}
              </select>
            )}

            {selectedUser && (
              <button onClick={handleLoginAsUser}>
                Se connecter
              </button>
            )}
          </>
        )}
      </div>
      <div className="new">
        <div className="btn new-chat" onClick={() => handleNewChat()}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          Nouveau chat
        </div>
      </div>
      <div className="sb history">
        <ul>
          {chats &&
            chats
              .filter((chat) => !chat.favorite)
              .reverse()
              .map((chat, index) => (
                <li
                  key={index}
                  className={chat.id === selectedChat.id ? "active" : ""}
                  title={chat.name}
                >
                  <div className="one" onClick={() => handleSelectChat(chat)}>
                    <svg
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4 conv"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    <p>{chat.name}</p>
                  </div>
                  <div className="two">
                    <svg
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStarClick(chat);
                      }}
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4 star"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2 L15.09 8.12 L22 9.27 L17 14.22 L18.18 21 L12 17.77 L5.82 21 L7 14.22 L2 9.27 L8.91 8.12 Z"></path>
                    </svg>
                    <svg
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTrashClick(chat);
                      }}
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4 trash"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </li>
              ))}
        </ul>
      </div>
      <div className="delete">
        <div className="btn delete-chats" onClick={() => handleDeleteChats()}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
          Supprimer chats
        </div>
      </div>
      <div className="sb favorite">
        <h2>Favoris</h2>
        <ul>
          {chats &&
            chats
              .filter((chat) => chat.favorite)
              .reverse()
              .map((chat, index) => (
                <li
                  key={index}
                  className={chat.id === selectedChat.id ? "active" : ""}
                  title={chat.name}
                >
                  <div className="one" onClick={() => handleSelectChat(chat)}>
                    <svg
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4 conv"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    <p>{chat.name}</p>
                  </div>
                  <div className="two">
                    <svg
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStarClick(chat);
                      }}
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4 star"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2 L15.09 8.12 L22 9.27 L17 14.22 L18.18 21 L12 17.77 L5.82 21 L7 14.22 L2 9.27 L8.91 8.12 Z"></path>
                    </svg>
                  </div>
                </li>
              ))}
        </ul>
      </div>
    </>
  );
};

export default ChatSidebar;
